/*
https://www.geeksforgeeks.org/build-a-basic-react-app-that-display-hello-world/
*/

/*


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/
import React from 'react';
import './App.css';



function App() {
	return (
		<h1> Hello Me ! </h1>
	);
}

export default App;
